<template>
  <div>
    <van-field label="击实次数" v-model.number="model.jiShiCiShu"></van-field>
    <van-field label="击实重量" v-model.number="model.jiShiZhongLiang"></van-field>
    <van-field label="击实直径" v-model.number="model.jiShiZhiJing"></van-field>
    <el-row type="flex" justify="space-around">
      <el-col :span="11">
        <van-button type="primary" block @click="saveModel">保存</van-button>
      </el-col>
      <el-col :span="11">
        <van-button type="warning" block to="/WapJSY002Query">我的记录</van-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";

Vue.use(Vant);
export default {
  data() {
    return {
      model: {},
    };
  },
  methods: {
    saveModel() {
      let that = this;
      that.axios.post("JSY002/SaveModel", that.model).then(function (response) {
        if (response.data.code == 101) {
          that.$notify({ type: "success", message: "保存成功" });
        }
      });
    },
  },
  mounted() {
    let that = this;
    that.axios
      .post("JSY002/GetModel", { onlyInt: parseInt(that.$route.query.id) })
      .then(function (response) {
        that.model = response.data.data;
      });
  },
};
</script>

<style>
</style>